import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/Seo"
import ContactForm from "../../components/ContactForm"
import { string } from '../../util/strings'
import { useSelector, useDispatch } from "react-redux"
import Hero from "../../components/hero"
import FramePlaceholder from "../../components/frame-placeholder"
import Hs from "../../images/h-sperator.svg"

const TalkToUsPage = ({ data, ...props }) => {

  const seo = {}

  const location = props.location
  const { search } = location

  const success = search === '?success'

  return (
    <Layout>
      <Seo {...seo} />
      <Hero
        className="mt-[90px] lg:mt-[126px]  h-[147px] lg:h-[350px] "
        title={'דברו איתנו'}
        subtitle={''}
        url={'/backgrounds/contact-us.svg'}
      />


      <section className="lg:pb-12 lg:pt-2 lg:w-[1280px] mx-auto" >
        <div className="container bg-no-repeat  lg:bg-[url(/backgrounds/pattern2.svg)] ">
          {success && <Success />}
          {!success &&
            <FramePlaceholder flip >
              <div className="space-y-8 py-12 px-4 lg:px-24  lg:shadow-lg lg:w-[960px] "
                //style={{ boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)" }}
              >
                <div>
                <h2 className="mb-2 text-blue text-[34px] lg:text-center font-bold"><img src={Hs} alt="" className="inline ml-1.5" />{string('contact.title')}</h2>
                <div className="hidden lg:block text-center">
                  התקשרו 08-6315353
                  ||
כתבו לנו {' '}
<a href="mailto:met@eec.co.il" className="text-blue">met@eec.co.il</a> 
{' '}
                  ||
                  או השאירו פרטים בטופס
                </div>
                </div>
                <ContactBlock />
              </div>
            </FramePlaceholder>
          }
        </div>
      </section>


      <section className="pt-16 pb-12">
        <div className="container flex flex-row justify-center">
          <div className="lg:px-24  lg:text-2xl  ">
            <div>טלפון: 086315353 שלוחה 7</div>
            <div>דואר אלקטרוני: met@eec.co.il</div>
          </div>
        </div>
      </section>
    </Layout >
  )
}

export default TalkToUsPage

const ContactBlock = () => {

  const id = 'rova'

  const forms = useSelector(state => state.contact.forms)
  const { message, loading } = forms.find(e => e.id === id)

  const dispatch = useDispatch()

  const sendContact = (values) => {

    //console.log('values', values)

    const data = {
      "field_name": [values.name],
      "field_phone": [values.phone],
      "field_email": [values.email],
      "contact_form": "talk_to_us",
      "subject": ["דברו איתנו"],
      //"field_subject": ["דברו איתנו"],
      "message": [values.text]
    }

    dispatch({ type: 'contact/send', payload: { id: id, data: data } })

  }

  return (
    <div className="-w-full -border-4">
      <ContactForm onSubmit={sendContact} loading={loading} message={message} />
    </div>
  )
}

const Success = () => {

  return (

    <div className="container my-8 text-center">
      <h2 className="text-2xl">תודה שכתבת לנו</h2>
      <div>אחד מהנציגים שלנו יחזור אלייך בהקדם</div>
    </div>
  )


}
export const Head = () => <title>דברו איתנו</title>
