import React from "react"


const Hero = ({ title, subtitle, image, url, urlMobile = false, className, ...props }) => {


  return (

    <>
      <section
        className={'bg-no-repeat bg-center-top bg-cover py-12 lg:py-28 lg:px-32 bg-[#F4F9FE] ' + (urlMobile !== false ? ' hidden lg:block ' : '') + className}
        style={{ backgroundImage: `url('${url}')` }}
      >
        <div className="mx-auto text-blue lg:text-center container">
          <h1 className="text-[28px] lg:text-[60px]">{title}</h1>
          <h2 className="text-base lg:text-[50px] leading-tight html-block" dangerouslySetInnerHTML={{ __html: subtitle }} />
        </div>
      </section>

      {urlMobile !== false &&

        <section
          className={'bg-no-repeat bg-center-top bg-cover py-12 lg:py-28 lg:px-32 bg-[#F4F9FE] ' + (urlMobile !== false ? ' block lg:hidden ' : '') + className}
          style={{ backgroundImage: `url('${urlMobile}')` }}
        >

          <div className="mx-auto text-blue text-center container">
            <h1 className="text-[28px] lg:text-[60px]">{title}</h1>
            <h2 className="text-base lg:text-[50px] leading-tight html-block" dangerouslySetInnerHTML={{ __html: subtitle }} />
          </div>
        </section>

      }
    </>

  )

}



export const HeroHome = ({ title, subtitle, relationships, className, ...props }) => {

  //console.log( props )
  //const image = relationships?.image?.localFile?.publicURL

  const subtitleHtml = subtitle ? subtitle.replace(',', ',<br/>') : ''

  //const video = '/rova_home.mp4'
  const video = '/rova.mp4'
  //const video = '/ADI2_1.mp4'

  return (

    <section
      className={' -bg-[#F4F9FE] relative ' + className}
    //style={{ backgroundImage: `linear-gradient(rgba(11, 11, 11, 0.5), rgba(11, 11, 11, 0.5)), url('${bgImage.publicURL}')` }}
    //style={{ backgroundImage: `url('${image}')` }}
    >

      <div className="video-wrapper -absolute -top-0 right-0 p-0 m-0 -h-[147px] -lg:h-[618px] overflow-hidden">
        <video playsInline autoPlay="autoplay" muted /* poster={image} */>
          <source src={video} type="video/mp4" />
          {/* Your browser does not support the video tag.
            הדפדפן אינו תומך בתג וידאו */}
        </video>
      </div>

      <div className="hidden lg:block lg:invisible text-blue container absolute top-8 lg:top-28 right-0 left-0 z-1 ">
        <h1 className="text-[28px] lg:text-[76px] ">{title}</h1>
        <h2 className="text-base lg:text-[34px] leading-tight	text-grey3 html-block" dangerouslySetInnerHTML={{ __html: subtitleHtml }} />
      </div>


    </section>

  )
}



export const HeroMinheletHome = ({ title, /* subtitle, */ relationships, className, ...props }) => {

  const image = relationships?.image?.localFile?.publicURL

  //const subtitleHtml = subtitle ? subtitle.replace(',', ',<br/>') : ''
  return (
    <section
      className={'py-12 lg:py-28 lg:px-32 bg-cover bg-[#F4F9FE] bg-center ' + className}
      //style={{ backgroundImage: `linear-gradient(rgba(11, 11, 11, 0.5), rgba(11, 11, 11, 0.5)), url('${bgImage.publicURL}')` }}
      style={{ backgroundImage: `url('${image}')` }}
    >

      <div className=" text-white  container flex justify-center  h-full items-center">
        <h1
          className="text-[28px] lg:text-[60px]  text-center font-bold drop-shadow-lg"
          style={{ textShadow: "0px 3px 3px rgba(0, 0, 0, 0.5)" }}
        >{title}</h1>
        {/* <h2 className="text-base lg:text-[34px] -max-w-[600px]  leading-tight	html-block" dangerouslySetInnerHTML={{ __html: subtitleHtml }} /> */}
      </div>
    </section >
  )
}


export const HeroMinhelet = ({ title, subtitle, image, url, className, ...props }) => {

  return (
    <section
      className={'bg-no-repeat bg-center-top bg-cover py-12 lg:py-28 lg:px-32 bg-[#F4F9FE] ' + className}
      style={{ backgroundImage: `linear-gradient(rgba(4, 91, 137, 0.36), rgba(4, 91, 137, 0.36)), url('${url}')` }}
    //style={{ backgroundImage: `url('${url}'), linear-gradient(rgba(4, 91, 137, 0.36), rgba(4, 91, 137, 0.36)) ` }}
    >

      <div className="mx-auto text-white text-center container">
        <h1
          className="text-[28px] lg:text-[60px] text-center font-bold drop-shadow-lg"
          style={{ textShadow: "0px 3px 3px rgba(0, 0, 0, 0.5)" }}
        >{title}</h1>
        <h2 className="text-base lg:text-[50px] -max-w-[600px] -border leading-tight	html-block" dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
    </section>
  )
}

export default Hero

